import { ImageQuery } from '@rategravity/marketing-components';
import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpColumns
} from '@rategravity/own-up-component-library';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { ReactNode } from 'react';
import { metadata } from '../../data/metadata/images';
import { DotStrip } from '../dot-strip';
import { LightHeader } from '../typography';

const getPressImages = (images: ImageQuery['images']) => {
  const pressImages = [...images.nodes];

  // Hard-coded order of the press images.
  return ['npr', 'forbes', 'yahoo-finance', 'us-news', 'bloomberg', 'usa-today'].map(
    (pressName) => {
      const pressImage = pressImages.find((node) => node.relativePath.includes(pressName));

      if (!pressImage) {
        return null;
      }

      return (
        <GatsbyImage
          image={pressImage.childImageSharp.gatsbyImageData}
          key={`press-image-${pressName}`}
          alt={metadata[pressImage.relativePath].alt}
          imgStyle={{ objectFit: 'scale-down' }}
        />
      );
    }
  );
};

const columnProps = {
  count: {
    small: 2,
    medium: 3,
    large: 6
  },
  style: {
    padding: 32
  }
};

/**
 * Return images based on the graphQL query.
 */
export const QueriedPress = () => {
  const { images }: ImageQuery = useStaticQuery(graphql`
    {
      images: allFile(filter: { relativePath: { regex: "/press-/" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(width: 210, placeholder: BLURRED, layout: CONSTRAINED)
          }
        }
      }
    }
  `);

  const pressImages = getPressImages(images);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <LightHeader>As featured in</LightHeader>
      <div style={{ width: '100%' }}>
        <OwnUpColumns {...columnProps}>{pressImages}</OwnUpColumns>
      </div>
    </div>
  );
};

const PressWrapperStyle = createOwnUpStyle({
  width: '100%',
  marginTop: 50,
  variants: {
    mediumAndDown: {
      marginTop: 0
    }
  }
});

const PressWrapper = createOwnUpComponent('section', PressWrapperStyle);

/**
 * Container for the logos for publications.
 */
export const PressMentions = ({ children }: { children: ReactNode }) => {
  return (
    <PressWrapper aria-label="Own Up in the press">
      {children}
      <DotStrip />
    </PressWrapper>
  );
};
